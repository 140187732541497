/* theme.css */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --background-color: #fdf6ed;
  --primary-color: #5D87FF;
  --primary-light: #ECF2FF;
  --primary-dark: #4570EA;
  --secondary-color: #49BEFF;
  --secondary-light: #E8F7FF;
  --secondary-dark: #23afdb;
  --text-color: #333333;
  --subtext-color: #666666;
  --card-background: #ffffff;
  --highlight-color: #800020;
  --border-radius: 12px;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --tooltip-background-color: #800020;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

@media (max-width: 600px) {
  .App-content {
    padding-bottom: 200px;
  }
}

.hero-section {
  background: url('./assets/hero-background.png') no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 50px 10px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--text-color);
}

.hero-title {
  font-size: 3rem;
  color: #ffffff;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.instruction-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.mascot-container {
  position: relative;
  display: flex;
  align-items: center;
}

.mascot-image {
  width: 150px;
  margin-right: 20px;
}

.tooltip {
  background: var(--tooltip-background-color);
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  width: 300px;
  text-align: left;
  box-shadow: var(--box-shadow);
  position: relative;
}

.tooltip:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: var(--tooltip-background-color);
  border-left: 0;
  margin-top: -10px;
}

@media (max-width: 600px) {
  .mascot-image {
    width: 120px;
  }

  .tooltip {
    width: 240px;
  }

  .tooltip:after {
    left: -8px;
    border-right-color: var(--tooltip-background-color);
    margin-top: -8px;
  }
}

.App-form {
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 25px;
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--text-color);
}

.form-grid .MuiTextField-root {
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  color: var(--text-color);
}

.MuiOutlinedInput-root {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  color: var(--text-color);
}

.MuiInputLabel-root {
  color: var(--subtext-color);
}

button.MuiButton-containedPrimary {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  color: #FFFFFF;
}

button.MuiButton-containedPrimary:hover {
  background-color: darken(var(--primary-color), 10%);
}

.MuiCard-root {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--text-color);
}

.MuiCardHeader-root {
  background-color: var(--primary-color);
  color: #FFFFFF;
}

.MuiTypography-body1 {
  color: var(--text-color);
}

.MuiTypography-h2 {
  color: var(--primary-color);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
}

.MuiTypography-h5 {
  color: #1d1d1d;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
}

.custom-card-header {
  background-color: var(--highlight-color);
  color: #FFFFFF;
}

.donation-footer {
  width: 100%;
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.donation-footer .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.donation-footer .footer-content p {
  margin: 0;
  color: #FFFFFF;
}

.donation-footer .footer-content .donate-button-container {
  margin-top: 0;
}

@media (max-width: 600px) {
  .donation-footer .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .donation-footer .footer-content .donate-button-container {
    margin-top: 10px;
  }

  .donation-footer {
    padding-bottom: 70px;
  }
}

.idea-generator {
  padding: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--text-color);
  position: relative;
}

.idea-generator::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border-radius: var(--border-radius);
  background: linear-gradient(45deg, #800020, #DAA520, #228B22, #003366);
  z-index: -1;
  background-size: 400% 400%;
  opacity: 50%;
  animation: rainbow-border 15s ease infinite;
}

@keyframes rainbow-border {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.testimonials-slider {
  margin: 20px 0;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
}

.testimonial {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
}

.testimonial-text {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 10px;
}

.shareable-summary-container {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 1080px;
  height: 1920px;
}

.shareable-summary-content {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #FFFFFF;
  color: #333333;
  border-radius: 12px;
}

.shareable-summary-title {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 20px;
}

.shareable-summary-field {
  margin-bottom: 10px;
  color: var(--text-color);
}

.summary-item {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  text-align: center;
}

.summary-title {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.summary-value {
  color: var(--text-color);
}

.summary-footer {
  text-align: center;
  margin-top: 20px;
  color: var(--secondary-color);
  font-weight: bold;
}

.summary-hero {
  padding: 40px 20px;
  margin-bottom: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--text-color);
  background: url('./assets/hero-background.png') no-repeat center center;
  background-size: cover;
}

.care-package-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.care-package-card .MuiCardContent-root {
  flex-grow: 1;
}

.care-package-card .MuiCardActions-root {
  justify-content: flex-end;
}

.blog-title-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.blog-title-link:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.blog-card {
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: inherit;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card-content {
  flex-grow: 1;
}

.blog-card-date {
  margin-bottom: 10px;
  color: grey;
}

.blog-card-summary {
  color: grey;
}

@media (min-width: 960px) {
  .blog-card-container {
    display: flex;
    justify-content: space-between;
  }
}

.full-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--primary-color);
  color: #ffffff;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--primary-color);
  color: #212452;
}

.bottom-nav .MuiButtonBase-root {
  color: #212452;
}

.nav-icon {
  color: #212452;
}

.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
  display: block;
  height: 100%;
}

.react-datepicker__input-container input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.additional-fields-header {
  background-color: var(--primary-color);
  color: #1c1d5c;
  padding: 10px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 10px;
}

.instruction-icon {
  position: absolute;
  top: -10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 10;
}

.mascot-container {
  position: relative;
  display: flex;
  align-items: center;
}

.mascot-image {
  width: 150px;
  margin-right: 20px;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  outline: none;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  left: 530px;
  top: 0px;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

.close-button svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 600px) {
  .mascot-image {
    width: 100px;
  }

  .mascot-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .tooltip {
    width: 240px;
  }

  .tooltip:after {
    left: -8px;
    border-right-color: var(--tooltip-background-color);
    margin-top: -8px;
  }
}

.results-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.result-icon {
  margin: 16px;
}

.result-hours {
  font-size: 1.2rem;
  color: #333;
}

.additional-fields-header {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.custom-card-header {
  background-color: #f5f5f5;
}
