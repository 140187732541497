/* shareableSummary.css */
.shareable-summary-container {
  width: 1080px;
  height: 1920px;
  padding: 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shareable-summary-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.summary-hero {
  width: 100%;
  padding: 40px 20px;
  text-align: center;
  background: url('./assets/hero-background.png') no-repeat center center;
  background-size: cover;
  border-radius: 20px;
}

.hero-title {
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.summary-grid {
  width: 100%;
  margin-top: 20px;
}

.summary-item {
  background-color: #f5f5f5;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 1.5rem;
  color: #4a90e2;
  margin-bottom: 10px;
}

.summary-value {
  font-size: 1.2rem;
  color: #333333;
}

.summary-footer {
  margin-top: 40px;
  font-size: 1.2rem;
  color: #666666;
}
